import { useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Title from "antd/es/typography/Title";
import notification from "antd/es/notification";
import { Button } from "antd";
import colors from "../../../theme/thiana";
import { apiUsers } from "../../../api/api-thiana-client/Configuration";

export default function Activate() {
  let navigate = useNavigate();
  let { a } = useParams();
  let activateRef = useRef<boolean>(false);

  useEffect(() => {
    // On tente d'activer le compte avec le token passé dans l'url
    if (!activateRef.current) {
      activateRef.current = true;
      if (a) {
        (async () => {
          let response = await apiUsers.authUserActivateRaw({
            headers: { "X-Access-Token": a },
          });
          if (response.raw.status === 200) {
            notification.success({
              message: "Activation du compte réussie.",
              description: "Votre compte est désormais actif.",
            });
            navigate("/informations");
          } else {
            navigate("/logout");
            notification.error({
              message: "Erreur",
              description: "Erreur lors de l'activation de votre compte.",
            });
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendEmail = async () => {
    let response = await apiUsers.sendActivationEmailRaw();
    if (response.raw.status === 200) {
      notification.success({
        message: "Email envoyé",
        description:
          "Un email pour activer votre compte vient de vous être envoyé.",
      });
    } else {
      navigate("/logout");
      notification.error({
        message: "Erreur",
        description: "Erreur lors de l'envoi du lien d'activation par email.",
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: "10%",
        paddingLeft: "10%",
      }}
    >
      <div style={{ fontSize: 30, fontWeight: "bold" }}></div>
      <Title level={1}>Activez votre compte</Title>
      <div style={{ fontSize: 14, textAlign: "justify" }}>
        Lors de votre inscription, un email avec un lien de validation a été
        envoyé à votre adresse email. Cliquez sur le lien de validation pour
        valider votre compte. Si vous ne trouvez pas l'email, pensez à vérifier
        dans vos spams.
      </div>

      <div style={{ fontSize: 14, textAlign: "justify" }}>
        Si vous n'avez pas reçu l'email ou que vous ne le retrouvez pas,{" "}
        <span
          onClick={sendEmail}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          cliquez ici pour recevoir un nouvel email.
        </span>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          type="text"
          onClick={() => navigate("/logout")}
          style={{
            color: colors.thiana.grey[500],
            marginTop: 20,
          }}
        >
          Déconnexion
        </Button>
      </div>
    </div>
  );
}
